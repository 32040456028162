import { Utils } from '@/modules/utils';

export class ImageGenerator {
  static _host = process.env.VUE_APP_IMAGE_GENERATOR_HOST;

  static getAllAssets () {
    return this.getAssets(['font', 'panel', 'icon']);
  }

  static getFontAssets () {
    return this.getAssets(['font']);
  }

  static getAssets (including) {
    const queryParameters = including.map(value => `including=${value}`).join('&');
    const url = `${this._host}/assets?${queryParameters}`;

    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch assets');
        }
        return response.json();
      });
  }

  static panelUrl (panelId) {
    return `${this._host}/assets/panel/${panelId}`;
  }

  static iconUrl (iconId) {
    return `${this._host}/assets/icon/${iconId}`;
  }

  static fontUrl (fontId) {
    return `${this._host}/assets/font/${fontId}`;
  }

  static generatePanelUrl (options) {
    const params = [];

    Object.keys(options).forEach(key => {
      const param = Utils.camelToKebabCase(key);
      let value = options[key];
      if (param.includes('color')) {
        value = this.prepareColor(value);
      }

      value = encodeURIComponent(value);

      params.push(`${param}=${value}`);
    });

    const url = `${this._host}/panel?${params.join('&')}`;

    return url;
  }

  static generateEmoteUrl (options) {
    const params = [];

    Object.keys(options).forEach(key => {
      const param = Utils.camelToKebabCase(key);
      const values = options[key];

      if (Array.isArray(values)) {
        values.forEach(value => {
          value = encodeURIComponent(value);
          params.push(`${param}=${value}`);
        });
      } else {
        let value = values;

        if (param.includes('color')) {
          value = this.prepareColor(value);
        }

        value = encodeURIComponent(value);
        params.push(`${param}=${value}`);
      }
    });

    const url = `${this._host}/emote?${params.join('&')}`;

    return url;
  }

  static prepareColor (color) {
    let preparedColor = color.replace('#', '');
    if (preparedColor.length === 6) preparedColor += 'FF';
    return preparedColor;
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          imgGenerator: ImageGenerator,
        };
      },
    });
  },
};
