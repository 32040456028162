<template>
  <v-btn
      v-bind="props"
      :variant="utils.buttonVariant"
      color="primary"
      class="kicklet-button"
      v-ripple="{ class: `text-white` }"
      :class="{'hover-size': zoomOnHover}"
  >
    <template v-slot:default>
      <span class="font-weight-bold">
        <slot>{{props.text}}</slot>
      </span>
    </template>
  </v-btn>
</template>

<script setup>

const props = defineProps({
  text: {
    type: String,
    default: 'Text',
  },
  zoomOnHover: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped>
.kicklet-button{
  box-shadow: none;
  border-radius: 12px;
  padding: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hover-size{
  transition: transform 0.3s ease;
}
.hover-size:hover {
  transform: scale(1.1);
}
</style>
