<template>
  <kicklet-button
    :style="buttonStyle"
    color="primary"
    :class="{glow: glow}"
    v-bind="props"
  >
    <template v-slot:default>
      <span class="font-weight-bold" :class="{ 'text-shadow': textShadow }">
        <slot>{{props.text}}</slot>
      </span>
    </template>
  </kicklet-button>
</template>

<script setup>
import { computed } from 'vue';
import KickletButton from '@/components/custom/kicklet/KickletButton.vue';

const props = defineProps({
  text: {
    type: String,
    default: 'Text',
  },
  color1: {
    type: String,
    default: '#97CB2A',
  },
  color2: {
    type: String,
    default: '#11a624',
  },
  glow: {
    type: Boolean,
    default: false,
  },
  zoomOnHover: {
    type: Boolean,
    default: false,
  },
  textShadow: {
    type: Boolean,
    default: false,
  },
});

const buttonStyle = computed(() => ({ background: `linear-gradient(to right, ${props.color1}, ${props.color2})` }));
</script>

<style scoped>
@keyframes glowAnimation {
  0% {
    box-shadow: 0 0 8px v-bind(props.color1), 0 0 8px v-bind(props.color2);
  }
  50% {
    box-shadow: 0 0 8px v-bind(props.color2), 0 0 8px v-bind(props.color1);
  }
  100% {
    box-shadow: 0 0 8px v-bind(props.color1), 0 0 8px v-bind(props.color2);
  }
}

.glow {
  animation: glowAnimation 1s infinite;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
