import Pusher from 'pusher-js';
import { Utils } from '@/modules/utils';

export class Kick {
  static kickApi = 'https://kick.com/api';

  static streamerDataCache = {};
  static cacheDuration = 60000;

  static getStreamerData (streamer) {
    const now = Date.now();
    const cachedData = this.streamerDataCache[streamer];

    if (cachedData && (now - cachedData.timestamp < this.cacheDuration)) {
      return Promise.resolve(cachedData.data);
    } else {
      return fetch(`${this.kickApi}/v2/channels/${streamer}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Request failed with status ' + response.status);
          }
        })
        .then(data => {
          this.streamerDataCache[streamer] = { data, timestamp: Date.now() };
          return data;
        });
    }
  };

  static async getProfilePicUrl (streamer) {
    if (!streamer) {
      return Utils.getImgUrl('user_profile_pic.png');
    } else {
      return await this.getStreamerData(streamer)
        .then(data => {
          if (data.user.profile_pic) {
            return data.user.profile_pic;
          } else {
            return Utils.getImgUrl('user_profile_pic.png');
          }
        });
    }
  }

  static getChannelMessages (channelId) {
    return fetch(`${this.kickApi}/v2/channels/${channelId}/messages`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Request failed with status ' + response.status);
        }
      });
  }

  static getEmotes (channelName) {
    return fetch('https://kick.com/emotes/' + channelName)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Request failed with status ' + response.status);
        }
      });
  }

  static getEmoteUrl (emoteId) {
    return `https://files.kick.com/emotes/${emoteId}/fullsize`;
  }

  static getPusher () {
    return new Pusher('32cbd69e4b950bf97679', {
      cluster: 'us2',
      forceTLS: true,
    });
  }

  static createTestMessage (sender = '', message = '', options = {}) {
    return {
      id: '1',
      chat_id: 1,
      user_id: 1,
      content: message,
      type: 'message',
      metadata: null,
      created_at: '2023-07-31T20:54:35Z',
      sender: {
        id: 1,
        slug: sender.toLowerCase(),
        username: sender,
        identity: {
          color: options.senderColor || '#75FD46',
          badges: options.badges || [],
        },
      },
    };
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          kick: Kick,
        };
      },
    });
  },
};
