<template>
  <v-dialog
      v-model="dialog"
      :persistent="persistent"
      transition="fab-transition"
      scrim="black"
      :max-width="maxWidth"
  >
    <v-card :height="height" class="rounded-xl" :class="{ 'pa-2': padding, 'pa-0': !padding }">
      <slot name="alert"></slot>
      <v-card-title class="font-weight-bold" :class="{ 'pa-4': !padding }">
        <slot name="title"></slot>
        <v-icon
          icon="mdi-close"
          class="close-btn"
          @click="close"
        />
      </v-card-title>
      <v-card-text class="scrollable-content" :class="{ 'pa-0': !padding }">
          <slot name="content"></slot>
      </v-card-text>
      <v-card-actions class="w-100 flex-row-reverse" :class="{'d-flex': actionDFlex}">
        <div
            :class="{'d-flex': actionDFlex}"
          class="w-100"
          style="max-width: 500px; padding: 1rem;"
        >
          <slot name="actions"></slot>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: Boolean,
  closable: {
    type: Boolean,
    default: true,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  height: {
    type: [String, Number],
    default: undefined,
  },
  maxWidth: {
    type: [String, Number],
    default: '500px',
  },
  padding: {
    type: Boolean,
    default: true,
  },
  actionDFlex: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue', 'close']);

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});

function close () {
  dialog.value = false;
  emit('close');
}
</script>

<style scoped>
.scrollable-content {
  flex-grow: 1;
  overflow: auto;
}

.close-btn {
  z-index: 1;
  position: absolute;
  top: 17px;
  right: 10px;
  color: rgb(var(--v-theme-primary));
}
</style>
