import { ref } from 'vue';

export class Breadcrumb {
  static data = new Map();

  static set (key, value) {
    if (!Breadcrumb.data.has(key)) {
      Breadcrumb.data.set(key, ref(value));
    } else {
      Breadcrumb.data.get(key).value = value;
    }
  }

  static get (key) {
    if (!Breadcrumb.data.has(key)) {
      Breadcrumb.data.set(key, ref(undefined));
    }
    return Breadcrumb.data.get(key);
  }
}
