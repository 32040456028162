<template>
  <v-list density="compact" :nav="true">
    <v-list-item to="/" class="pt-0 pb-0 ma-0">
      <template #prepend>
        <img class="banner" alt="banner" :src="utils.getImgUrl('logo.png')" />
      </template>
      <template #title v-if="!minified">
        <span
          class="banner-font color-primary ml-2"
        >KICKLET</span>
      </template>
    </v-list-item>
    <v-divider/>
  </v-list>
  <!-- Default Menu -->
  <v-list density="compact" aria-label="page" :nav="true">
    <scroll-animation v-if="!minified" :delay="100" :instant="true" animation-type="fadeInRight">
      <get-premium-button />
    </scroll-animation>
    <div v-if="$vuetify.display.xs && editingAs">
      <collaborator-switcher @switched="emit('close')" />
      <v-divider class="mb-3" />
    </div>
    <div v-for="(group, groupName, index) in filteredGroupedItems" :key="groupName">
      <scroll-animation :delay="300 + index * 100" :instant="true" animation-type="fadeInRight">
        <v-list-subheader v-if="!(group.length === 0) && !minified && groupName !== 'home'" class="navigation-title">{{ $t('navigation.' + groupName) }}</v-list-subheader>
        <v-list-item
            style="margin-top: 0; margin-bottom: 0;"
            v-for="item in group"
            :key="item.title"
            class="clickable rounded-lg"
            :to="item.to"
            :href="item.href"
            :target="item.href ? '_blank' : undefined"
            :slim="true"
        >
          <template v-slot:default="{ isActive }">
            <v-list-item-title :class="{ 'text-primary': isActive }">
              <span class="navigation-text">{{ $t(item.title) }}</span>
              <v-chip v-if="item.hot" class="ml-1" color="orange" size="x-small">🔥</v-chip>
              <v-chip v-if="item.new" class="ml-1" color="yellow" size="x-small">✨</v-chip>
            </v-list-item-title>
          </template>
          <template v-slot:prepend="{ isActive }">
            <v-icon v-if="item.mdiIcon" :class="{ 'text-primary': isActive }">{{ item.mdiIcon }}</v-icon>
            <img v-if="item.svg" :src="utils.getSvgUrl(item.svg)" height="24" style="opacity: 0.7;" class="mr-5">
          </template>
        </v-list-item>
        <v-divider v-if="!(group.length === 0) && !minified && index < Object.keys(filteredGroupedItems).length - 1" class="mt-3 mb-3"></v-divider>
      </scroll-animation>
    </div>
  </v-list>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { Utils } from '@/modules/utils';
import ScrollAnimation from '@/components/custom/ScrollAnimation.vue';
import CollaboratorSwitcher from '@/components/CollaboratorSwitcher';
import GetPremiumButton from '@/components/custom/GetPremiumButton.vue';

defineProps({
  minified: {
    type: Boolean,
    default: true,
  },
  editingAs: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close']);

const store = useStore();

const filteredGroupedItems = computed(() => {
  const result = {};

  for (const groupName in groupedItems) {
    result[groupName] = groupedItems[groupName].filter(item => {
      if (item.loginRequired && !store.getters.isLoggedIn) {
        return false;
      }

      if (item.requiresGroup) {
        return store.getters.tokenHasSomeGroup(item.requiresGroup);
      }

      return true;
    });
  }

  return result;
});

const groupedItems = {
  home: [
    {
      title: 'dashboard.title',
      to: '/dashboard',
      mdiIcon: 'mdi-view-dashboard',
      new: true,
      loginRequired: true,
    },
    {
      title: 'private-profile.title',
      to: '/profile',
      mdiIcon: 'mdi-clipboard-text',
      new: true,
      loginRequired: true,
    },
    {
      title: 'collaborators.title',
      to: '/collaborators',
      mdiIcon: 'mdi-handshake',
      loginRequired: true,
    },
    {
      title: 'admin.title',
      value: 'admin',
      to: '/admin',
      mdiIcon: 'mdi-lock',
      requiresGroup: ['admin'],
      loginRequired: true,
    },
  ],
  commandsAndTimers: [
    {
      title: 'bot.commands.title',
      value: 'commands',
      to: '/commands',
      mdiIcon: 'mdi-hammer',
      loginRequired: true,
    },
    {
      title: 'bot.global-messages.title',
      value: 'global-messages',
      to: '/global-messages',
      mdiIcon: 'mdi-chat',
      loginRequired: true,
    },
    {
      title: 'bot.periodic-messages.title',
      value: 'periodic-messages',
      to: '/periodic-messages',
      mdiIcon: 'mdi-clock',
      loginRequired: true,
    },
  ],
  userInteraction: [
    {
      title: 'bot.moderation.title',
      value: 'moderation',
      to: '/moderation',
      mdiIcon: 'mdi-shield',
      loginRequired: true,
    },
    {
      title: 'bot.alert-messages.title',
      value: 'alert-messages',
      to: '/alert-messages',
      mdiIcon: 'mdi-alert',
      loginRequired: true,
    },
    {
      title: 'bot.song-requests.title-menu',
      value: 'song-requests',
      to: '/song-requests',
      mdiIcon: 'mdi-music',
      loginRequired: true,
      hot: true,
    },
    {
      title: 'bot.points.title',
      value: 'points',
      to: '/point-settings',
      mdiIcon: 'mdi-star',
      loginRequired: true,
    },
    {
      title: 'giveaway.title',
      value: 'giveaway',
      to: '/giveaway',
      mdiIcon: 'mdi-gift',
      loginRequired: true,
    },
    {
      title: 'shop.title',
      value: 'shop',
      to: '/shop',
      mdiIcon: 'mdi-cart',
      loginRequired: true,
    },
  ],
  dataManagement: [
    {
      title: 'variables',
      value: 'variables',
      to: '/variables',
      mdiIcon: 'mdi-link-variant',
      loginRequired: true,
    },
    {
      title: 'bot.stats.title',
      value: 'stats',
      to: '/stats',
      mdiIcon: 'mdi-chart-bar',
      loginRequired: true,
    },
  ],
  graphic: [
    {
      title: 'widgets.title',
      to: '/widgets/saved',
      mdiIcon: 'mdi-video',
      hot: true,
      loginRequired: true,
    },
    {
      title: 'panels.title',
      to: '/panels',
      mdiIcon: 'mdi-image-frame',
    },
    {
      title: 'emotes.title',
      to: '/emotes',
      mdiIcon: 'mdi-emoticon',
    },
  ],
  utils: [
    {
      title: 'extension.title',
      to: '/extension',
      mdiIcon: 'mdi-puzzle',
    },
    {
      title: 'vod-downloader.title',
      to: '/vod-downloader',
      mdiIcon: 'mdi-movie',
      hot: true,
    },
    {
      title: 'chat-downloader.title',
      to: '/chat-downloader',
      mdiIcon: 'mdi-chat',
    },
    {
      title: 'multi-stream.title',
      to: '/multi-stream',
      mdiIcon: 'mdi-television',
    },
    {
      title: 'user-lookup.title',
      to: '/user-lookup',
      mdiIcon: 'mdi-magnify',
    },
  ],
  help: [
    {
      title: 'discord.title',
      href: Utils.DISCORD_INVITE,
      svg: 'discord',
    },
    {
      title: 'docs.title',
      href: '/docs',
      mdiIcon: 'mdi-book-open',
    },
  ],
};
</script>

<style scoped>
.navigation-text{
  font-size: 12px;
}
.navigation-title{
  font-size: 12px;
  font-weight: bold;
}

.banner {
  width: 30px;
  height: 30px;
}
</style>
