export class DiscordAuth {
  static gateway = process.env.VUE_APP_API_GATEWAY;
  static clientId = process.env.VUE_APP_DISCORD_CLIENT_ID;
  static redirectUri = process.env.VUE_APP_DISCORD_REDIRECT;
  static scope = 'identify';

  static login () {
    const url = `${this.gateway}/auth/discord`;
    window.open(url, 'popup', 'width=600,height=720');
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          discord: DiscordAuth,
        };
      },
    });
  },
};
