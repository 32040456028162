const Profile = () => import('@/views/profile/Profile.vue');

const routes = [
  {
    path: 'usage',
    name: 'ProfileUsage',
    meta: {
      breadcrumb: 'Usage',
    },
    component: Profile,
  },
  {
    path: 'premium',
    name: 'ProfilePremium',
    meta: {
      breadcrumb: 'Premium',
    },
    component: Profile,
  },
  {
    path: 'settings',
    name: 'ProfileSettings',
    meta: {
      breadcrumb: 'Settings',
    },
    component: Profile,
  },
];

export default routes;
