import router from '@/router';

const storedMe = localStorage.getItem('me');
let parsedMe = null;

try {
  parsedMe = JSON.parse(storedMe);
} catch (error) {}

const state = {
  me: parsedMe,
  selectedCollaborator: null,
  subscription: undefined,
  limits: undefined,
};

const getters = {
  me: (state) => state.me,
  selectedCollaborator: (state) => state.selectedCollaborator,
  isLoggedIn: (state) => {
    return !!state.me;
  },
  tokenGroups: (state) => {
    return state.me?.groups;
  },
  user: (state) => {
    return state.me?.user;
  },
  userId: (state, getters) => {
    return getters.user?.id;
  },
  kickId: (state, getters) => {
    return getters.user?.kickId;
  },
  tokenHasGroup: (state, getters) => (group) => {
    const groups = getters.tokenGroups;
    if (groups) {
      return groups.includes(group);
    }
    return false;
  },
  tokenHasSomeGroup: (state, getters) => (groups) => {
    const tokenGroups = getters.tokenGroups;
    if (tokenGroups && Array.isArray(groups)) {
      return groups.some(group => tokenGroups.includes(group));
    }
    return false;
  },
  isVerified: (state, getters) => {
    return getters.tokenHasGroup('verified');
  },
  isBetaTester: (state, getters) => {
    return getters.tokenHasGroup('bot-beta');
  },
  subscription: (state) => {
    return state.subscription;
  },
  isPremium: (state, getters) => {
    if (state.subscription) {
      const currentDate = new Date();
      const expirationDate = new Date(state.subscription.expiration);

      return currentDate < expirationDate;
    }
    return false;
  },
  isPremiumOrHigher: (state, getters) => {
    if (getters.tokenHasSomeGroup(['admin', 'vip'])) {
      return true;
    }

    return getters.isPremium;
  },
  kickUsername: (state, getters) => {
    return getters.user?.kickUsername;
  },
  kickSlug: (state, getters) => {
    return getters.user?.kickSlug;
  },
  limits: (state) => {
    return state.limits;
  },
};

const mutations = {
  setMe (state, me) {
    let freshLogin = false;
    if (!state.me && me) {
      freshLogin = true;
    }
    state.me = me;
    if (freshLogin) {
      router.push('/dashboard');
    }
  },
  setSelectedCollaborator (state, collaborator) {
    state.selectedCollaborator = collaborator;
  },
  saveMe (state) {
    if (!state.me) {
      localStorage.removeItem('me');
    } else {
      localStorage.setItem('me', JSON.stringify(state.me));
    }
  },
  setSubscription (state, subscription) {
    state.subscription = subscription;
  },
  setLimits (state, limits) {
    state.limits = limits;
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
