import store from '@/store';
import { Backend } from '@/services/Backend';

export class StoreProvider {
  static get subscription () {
    this.loadSubscription();
    return store.getters.subscription;
  }

  static get isPremium () {
    this.loadSubscription();
    return store.getters.isPremium;
  }

  static get isPremiumOrHigher () {
    this.loadSubscription();
    return store.getters.isPremiumOrHigher;
  }

  static get limits () {
    this.loadLimits();
    return store.getters.limits;
  }

  static loadSubscription () {
    if (store.getters.subscription === undefined) {
      store.commit('setSubscription', null);
      Backend.stripeSubscription()
        .then(resp => {
          store.commit('setSubscription', resp.data);
        })
        .catch(resp => {
          store.commit('setSubscription', false);
        });
    }
  }

  static loadLimits () {
    if (store.getters.limits === undefined) {
      store.commit('setLimits', null);
      Backend.getLimits()
        .then(resp => {
          store.commit('setLimits', resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          provider: StoreProvider,
        };
      },
    });
  },
};
