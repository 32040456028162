/* eslint-disable no-template-curly-in-string */
import * as monaco from 'monaco-editor';
export function init () {
  registerLanguage();
  registerTokenProvider();
  registerCompletionItemProvider();
}

function registerLanguage () {
  monaco.languages.register({ id: 'kicklet' });
  monaco.languages.setLanguageConfiguration('kicklet', {
    brackets: [
      ['{{', '}}'],
      ['{', '}'],
      ['(', ')'],
    ],
    autoClosingPairs: [
      { open: '{{', close: '}}', notIn: ['script', 'string', 'comment'] },
      { open: '(', close: ')', notIn: ['string'] },
      { open: '"', close: '"' },
      { open: '<template>', close: '</template>' },
      { open: '<script>', close: '</script>' },
    ],
    comments: {
      blockComment: ['{{/*', '*/}}'],
    },
  });

  monaco.editor.defineTheme('custom', {
    inherit: true,
    base: 'vs-dark',
    rules: [
      { token: 'keyword', foreground: '#cc7832' },
      { token: 'tag', foreground: '#66C967' },
      { token: 'function', foreground: '#09a875' },
    ],
    colors: [],
  });
}

function registerTokenProvider () {
  monaco.languages.setMonarchTokensProvider('kicklet', {
    templateKeywords: [
      'if', 'else', 'end', 'range', 'template', 'block', 'with', 'define',
      'eq', 'ne', 'lt', 'le', 'gt', 'ge',
      'add', 'sum', 'sub', 'mul', 'div', 'mod',
    ],

    jsKeywords: [
      'break', 'case', 'catch', 'class', 'continue', 'const',
      'constructor', 'debugger', 'default', 'delete', 'do', 'else',
      'export', 'extends', 'false', 'finally', 'for', 'from', 'function',
      'get', 'if', 'import', 'in', 'instanceof', 'let', 'new', 'null',
      'return', 'set', 'super', 'switch', 'symbol', 'this', 'throw', 'true',
      'try', 'typeof', 'undefined', 'var', 'void', 'while', 'with', 'yield',
      'async', 'await', 'of',
    ],

    jsTypeKeywords: [
      'any', 'boolean', 'number', 'object', 'string', 'undefined',
    ],

    jsOperators: [
      '<=', '>=', '==', '!=', '===', '!==', '=>', '+', '-', '**',
      '*', '/', '%', '++', '--', '<<', '</', '>>', '>>>', '&',
      '|', '^', '!', '~', '&&', '||', '?', ':', '=', '+=', '-=',
      '*=', '**=', '/=', '%=', '<<=', '>>=', '>>>=', '&=', '|=',
      '^=', '@',
    ],

    // we include these common regular expressions
    symbols: /[=><!~?:&|+\-*/^%]+/,
    escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
    digits: /\d+(_+\d+)*/,
    octaldigits: /[0-7]+(_+[0-7]+)*/,
    binarydigits: /[0-1]+(_+[0-1]+)*/,
    hexdigits: /[[0-9a-fA-F]+(_+[0-9a-fA-F]+)*/,

    regexpctl: /[(){}[\]$^|\-*+?.]/,
    regexpesc: /\\(?:[bBdDfnrstvwW0\\/]|@regexpctl|c[A-Z]|x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4})/,

    tokenizer: {
      root: [
        [/<template>/, { token: 'tag.template', bracket: '@open', next: '@templateBlock' }],
        [/<script>/, { token: 'tag.script', bracket: '@open', next: '@script' }],
        { include: '@template' },
      ],

      templateBlock: [
        [/<\/template>/, { token: 'tag.template', bracket: '@close', next: '@pop' }],
        { include: '@template' },
      ],

      template: [
        // Comments
        [/\{\{\/\*[\s\S]*?\*\/}}/, 'comment'],

        [/\{\{/, { token: 'template.brackets', bracket: '@open', next: '@withinTemplateBrackets' }],
      ],

      withinTemplateBrackets: [
        [/}}/, { token: 'template.brackets', bracket: '@close', next: '@pop' }],

        // String literals
        [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-terminated string
        [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],

        // Variables
        [/\$\w+/, { token: 'variable' }],

        // identifiers and keywords
        [/[a-z_$][\w$]*/, {
          cases: {
            '@templateKeywords': 'keyword',
            '@default': 'type.identifier',
          },
        }],

        // numbers
        [/(@digits)[eE]([-+]?(@digits))?/, 'number.float'],
        [/(@digits)\.(@digits)([eE][-+]?(@digits))?/, 'number.float'],
        [/0[xX](@hexdigits)/, 'number.hex'],
        [/0[oO]?(@octaldigits)/, 'number.octal'],
        [/0[bB](@binarydigits)/, 'number.binary'],
        [/(@digits)/, 'number'],

        [/\b[A-Z][\w$]*/, 'variable'],
        [/[A-Z][\w$]*/, 'identifier'],

        [/[\s\S]/, 'template.within.brackets'],
      ],

      script: [
        [/<\/script>/, { token: 'tag.script', bracket: '@close', next: '@pop' }],
        [/[{}]/, 'delimiter.bracket'],

        // identifiers and keywords
        [/[a-z_$][\w$]*/, {
          cases: {
            '@jsTypeKeywords': 'keyword',
            '@jsKeywords': 'keyword',
            '@default': 'identifier',
          },
        }],
        [/[A-Z][\w$]*/, 'type.identifier'], // to show class names nicely

        // whitespace
        { include: '@whitespace' },

        // regular expression: ensure it is terminated before beginning (otherwise it is an opeator)
        [/\/(?=([^\\/]|\\.)+\/([gimsuy]*)(\s*)(\.|;|\/|,|\)|]|}|$))/, { token: 'regexp', bracket: '@open', next: '@regexp' }],

        // delimiters and operators
        [/[()[\]]/, '@brackets'],
        [/[<>](?!@symbols)/, '@brackets'],
        [/@symbols/, {
          cases: {
            '@jsOperators': 'delimiter',
            '@default': '',
          },
        }],

        // numbers
        [/(@digits)[eE]([-+]?(@digits))?/, 'number.float'],
        [/(@digits)\.(@digits)([eE][-+]?(@digits))?/, 'number.float'],
        [/0[xX](@hexdigits)/, 'number.hex'],
        [/0[oO]?(@octaldigits)/, 'number.octal'],
        [/0[bB](@binarydigits)/, 'number.binary'],
        [/(@digits)/, 'number'],

        // delimiter: after number because of .\d floats
        [/[;,.]/, 'delimiter'],

        // strings
        [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-teminated string
        [/'([^'\\]|\\.)*$/, 'string.invalid'], // non-teminated string
        [/"/, 'string', '@string_double'],
        [/'/, 'string', '@string_single'],
        [/`/, 'string', '@string_backtick'],
      ],

      whitespace: [
        [/[ \t\r\n]+/, ''],
        [/\/\*\*(?!\/)/, 'comment.doc', '@jsdoc'],
        [/\/\*/, 'comment', '@comment'],
        [/\/\/.*$/, 'comment'],
      ],

      comment: [
        [/[^/*]+/, 'comment'],
        [/\*\//, 'comment', '@pop'],
        [/[/*]/, 'comment'],
      ],

      jsdoc: [
        [/[^/*]+/, 'comment.doc'],
        [/\*\//, 'comment.doc', '@pop'],
        [/[/*]/, 'comment.doc'],
      ],

      // We match regular expression quite precisely
      regexp: [
        [/(\{)(\d+(?:,\d*)?)(})/, ['regexp.escape.control', 'regexp.escape.control', 'regexp.escape.control']],
        [/(\[)(\^?)(?=(?:[^\]\\/]|\\.)+)/, ['regexp.escape.control', { token: 'regexp.escape.control', next: '@regexrange' }]],
        [/(\()(\?:|\?=|\?!)/, ['regexp.escape.control', 'regexp.escape.control']],
        [/[()]/, 'regexp.escape.control'],
        [/@regexpctl/, 'regexp.escape.control'],
        [/[^\\/]/, 'regexp'],
        [/@regexpesc/, 'regexp.escape'],
        [/\\\./, 'regexp.invalid'],
        [/(\/)([gimsuy]*)/, [{ token: 'regexp', bracket: '@close', next: '@pop' }, 'keyword.other']],
      ],

      regexrange: [
        [/-/, 'regexp.escape.control'],
        [/\^/, 'regexp.invalid'],
        [/@regexpesc/, 'regexp.escape'],
        [/[^\]]/, 'regexp'],
        [/]/, { token: 'regexp.escape.control', next: '@pop', bracket: '@close' }],
      ],

      string_double: [
        [/[^\\"]+/, 'string'],
        [/@escapes/, 'string.escape'],
        [/\\./, 'string.escape.invalid'],
        [/"/, 'string', '@pop'],
      ],

      string_single: [
        [/[^\\']+/, 'string'],
        [/@escapes/, 'string.escape'],
        [/\\./, 'string.escape.invalid'],
        [/'/, 'string', '@pop'],
      ],

      string_backtick: [
        [/\$\{/, { token: 'delimiter.bracket', next: '@bracketCounting' }],
        [/[^\\`$]+/, 'string'],
        [/@escapes/, 'string.escape'],
        [/\\./, 'string.escape.invalid'],
        [/`/, 'string', '@pop'],
      ],

      bracketCounting: [
        [/\{/, 'delimiter.bracket', '@bracketCounting'],
        [/}/, 'delimiter.bracket', '@pop'],
        { include: 'script' },
      ],

      string: [
        [/[^\\"]+/, 'string'],
        [/\\./, 'string.escape'],
        [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }],
      ],
    },
  });
}

function registerCompletionItemProvider () {
  monaco.languages.registerCompletionItemProvider('kicklet', {
    provideCompletionItems: function (model, position) {
      const wordInfo = model.getWordUntilPosition(position);
      const wordRange = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: wordInfo.startColumn,
        endColumn: wordInfo.endColumn,
      };

      const textUntilPosition = model.getValueInRange({
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });

      const insideTemplate = textUntilPosition.match(/<template[^>]*>[\s\S]*/i);
      const insideScript = textUntilPosition.match(/<script[^>]*>[\s\S]*/i);

      const insideTemplateBrackets = /{{[^{}]*$/.test(textUntilPosition);

      function checkTextTrigger (regexPattern) {
        const isMatch = new RegExp(regexPattern + '\\.$').test(textUntilPosition);
        return isMatch;
      }

      const completions = [];

      if (!insideTemplate && !insideScript) {
        completions.push({
          label: 'setup',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: '<template>\n' +
            '{{/* Your template goes here */}}\n' +
            '</template>\n' +
            '\n' +
            '<script>\n' +
            '// Your Javascript goes here\n' +
            '</script>',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: {
            startLineNumber: 1,
            endLineNumber: 1,
            startColumn: 1,
            endColumn: 1,
          },
        });
      }

      // 0 = label, 1 = snippet in brackets, 2 = snippet outside brackets
      const templateSnippets = [
        ['if', 'if $1', '{{if $1}}\n{{end}}'],
        ['if-else', undefined, '{{if $1}}\n{{else}}\n{{end}}'],
        ['console', 'console', '{{console $1}}'],
        ['sender', 'sender', '{{sender}}'],
        ['GetCounter', 'kicklet.GetCounter "$1"', '{{kicklet.GetCounter "$1"}}'],
        ['SetCounter', 'kicklet.SetCounter "$1"', '{{kicklet.SetCounter "$1"}}'],
        ['CounterAdd', 'kicklet.CounterAdd "$1"', '{{kicklet.CounterAdd "$1"}}'],
        ['rand', 'rand 0$1 0', '{{rand 0$1 0}}'],
        ['randItem', 'randItem "$1" "$2" "$3"', '{{randItem "$1" "$2" "$3"}}'],
        ['arg', 'arg "$1"', '{{arg "$1"}}'],
        ['args', 'args', '{{args}}'],
        ['time', 'time "$1"', '{{time "$1"}}'],
        ['timeFmt', 'timeFmt "$1" "$2"', '{{timeFmt "$1" "$2"}}'],
        ['upper', 'upper "$1"', '{{upper "$1"}}'],
        ['lower', 'lower "$1"', '{{lower "$1"}}'],
        ['Followers', 'kick.Followers', '{{kick.Followers}}'],
        ['Viewers', 'kick.Viewers', '{{kick.Viewers}}'],
        ['Title', 'kick.Title', '{{kick.Title}}'],
        ['Category', 'kick.Category', '{{kick.Category}}'],
        ['Uptime', 'kick.Uptime', '{{kick.Uptime}}'],
        ['minecraft.Players', 'minecraft.Players "$1"', '{{minecraft.Players "$1"}}'],
        ['script.Call', 'script.Call "$1"', '{{script.Call "$1"}}'],
        ['script.Var', 'script.Var "$1"', '{{script.Var "$1"}}'],
        ['Commands', 'kicklet.Commands', '{{kicklet.Commands}}'],
        ['CommandsWithPermission', 'kicklet.CommandsWithPermission', '{{kicklet.CommandsWithPermission}}'],
        ['GetJson', '$response := http.GetJson "$1"', '{{$response := http.GetJson "$1"}}'],
        ['GetJsonWithHeaders', '$response := http.GetJsonWithHeaders "$1" (dict "Authorization" "$2")', '{{$response := http.GetJsonWithHeaders "$1" (dict "Authorization" "$2")}}'],
        ['add', 'add ${1:number1} ${2:number2}', '{{add ${1:number1} ${2:number2}}}'],
        ['sum', 'sum ${1:number1} ${2:number2}', '{{sum ${1:number1} ${2:number2}}}'],
        ['sub', 'sub ${1:number1} ${2:number2}', '{{sub ${1:number1} ${2:number2}}}'],
        ['mul', 'mul ${1:number1} ${2:number2}', '{{mul ${1:number1} ${2:number2}}}'],
        ['div', 'div ${1:number1} ${2:number2}', '{{div ${1:number1} ${2:number2}}}'],
        ['mod', 'mod ${1:number1} ${2:number2}', '{{mod ${1:number1} ${2:number2}}}'],
        ['var', '\\$${1:varName} := $2', '{{\\$${1:varName} := $2}}'],
        ['comment', undefined, '{{/* $1 */}}'],
        ['parseInt', 'parseInt ${1:number}', '{{parseInt ${1:number}}}'],
        ['parseFloat', 'parseFloat ${1:number}', '{{parseFloat ${1:number}}}'],
      ];

      const scriptSnippets = [
        ['function', 'function ${1:methodName}() {\n  ${2:// body}\n}'],
        ['log', 'console.log(${1:message});'],
        ['Kicklet', 'Kicklet.'],
        ['Kick', 'Kick.'],
        ['Discord', 'Discord.'],
        ['Minecraft', 'Minecraft.'],
        ['$event', '$event.'],
      ];

      const scriptFunctions = [
        {
          context: 'Kicklet',
          functions: [
            ['watchtime', 'watchtime(${1:userName})\n' +
            '        .then(resp => resp.data.watchtime);'],
            ['getCounter', 'getCounter(${1:counter})\n' +
            '    .then(resp => resp.data);'],
            ['setCounter', 'setCounter(${1:counter}, ${2:number})\n' +
            '    .then(resp => resp.data);'],
            ['counterAdd', 'counterAdd(${1:counter}, ${2:number})\n' +
            '    .then(resp => resp.data);'],
            ['getPoints', 'getPoints(${1:userName})\n' +
            '    .then(resp => resp.data.points);'],
            ['setPoints', 'setPoints(${1:userName}, ${2:points})\n' +
            '    .then(resp => resp.data);'],
            ['addPoints', 'addPoints(${1:userName}, ${2:points})\n' +
            '    .then(resp => resp.data);'],
            ['removePoints', 'removePoints(${1:userName}, ${2:points})\n' +
            '    .then(resp => resp.data);'],
            ['addPointsViewers', 'addPointsViewers(${1:points})\n' +
            '    .then(resp => resp.data);'],
            ['commands', 'commands()\n' +
            '    .then(resp => resp.data);'],
            ['commandsWithPermissions', 'commandsWithPermissions()\n' +
            '    .then(resp => resp.data);'],
            ['getCurrency', 'getCurrency();'],
            ['commandsExecutions', 'commandsExecutions(\'1d\')\n' +
            '    .then(resp => resp.data);'],
            ['getCurrentSong', 'getCurrentSong()\n' +
            '    .then(resp => resp.data.title);'],
            ['clearSongRequests', 'clearSongRequests();'],
            ['songRequest', 'songRequest(sender, ${1:video id}, ${1:max seconds});'],
            ['setTempGlobalVar', 'setTempGlobalVar(${1:variableName}, ${2:value}, ${3:seconds});'],
            ['getTempGlobalVar', 'getTempGlobalVar(${1:variableName});'],
            ['deleteTempGlobalVar', 'deleteTempGlobalVar(${1:variableName});'],
            ['setTempViewerVar', 'setTempViewerVar(${1:variableName}, ${2:userName}, ${3:value}, ${4:seconds});'],
            ['getTempViewerVar', 'getTempViewerVar(${1:variableName}, ${2:userName});'],
            ['deleteTempViewerVar', 'deleteTempViewerVar(${1:variableName}, ${2:userName});'],
            ['clearTempViewerVar', 'clearTempViewerVar(${1:userName});'],
          ],
        },
        {
          context: 'Kick',
          functions: [
            ['getChannelUser', 'getChannelUser(${1:userName})\n' +
            '        .then(resp => resp);'],
            ['getChannel', 'getChannel()\n' +
            '        .then(resp => resp);'],
          ],
        },
        {
          context: 'Discord',
          functions: [
            ['sendMessage', 'sendMessage(${1:url}, ${2:json});'],
          ],
        },
        {
          context: 'Minecraft',
          functions: [
            ['players', 'players(${1:ipAddress})\n' +
            '    .then(resp => resp.data);'],
          ],
        },
        {
          context: '\\$event',
          functions: [
            ['getSender', 'getSender()'],
          ],
        },
        {
          context: 'getSender\\(\\)',
          functions: [
            ['hasPermission', 'hasPermission(${1:permission})'],
          ],
        },
      ];

      function getFunctionsForContext (context) {
        const contextFunctions = scriptFunctions.find(cf => cf.context === context);
        return contextFunctions ? contextFunctions.functions : [];
      }

      function getAllContexts () {
        return scriptFunctions.map(cf => cf.context);
      }

      if (!insideScript) {
        const filtered = templateSnippets.filter(snip => {
          return (!insideTemplateBrackets && snip[2]) || (insideTemplateBrackets && snip[1]);
        });
        const items = filtered.map(snip => ({
          label: snip[0],
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: insideTemplateBrackets ? snip[1] : snip[2],
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: wordRange,
        }));
        completions.push(...items);
      } else {
        const contexts = getAllContexts();
        let pushed = false;
        contexts.forEach(context => {
          if (checkTextTrigger(context)) {
            const items = getFunctionsForContext(context).map(snip => ({
              label: snip[0],
              kind: monaco.languages.CompletionItemKind.Snippet,
              insertText: snip[1],
              insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
              range: wordRange,
            }));
            completions.push(...items);
            pushed = true;
          }
        });
        if (!pushed) {
          const scriptItems = scriptSnippets.map(snip => ({
            label: snip[0],
            kind: monaco.languages.CompletionItemKind.Snippet,
            insertText: snip[1],
            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            range: wordRange,
          }));
          completions.push(...scriptItems);
        }
      }
      return {
        suggestions: completions,
      };
    },
  });
}
