import axios from 'axios';
import store from '@/store';
import { DiscordAuth } from '@/services/DiscordAuth';

const apiToken = process.env.VUE_APP_API_TOKEN;

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_GATEWAY,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  if (apiToken && apiToken !== '') {
    config.headers.Authorization = `ApiToken ${apiToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const requestUrl = error.config.url;
      if (requestUrl !== '/auth/me') {
        DiscordAuth.login();
      }
    }

    return Promise.reject(error);
  },
);

axiosInstance.selectedUser = () => {
  if (store.getters.selectedCollaborator) {
    return store.getters.selectedCollaborator.user;
  }

  return store.getters.user;
};

axiosInstance.selectedUserId = () => {
  return axiosInstance.selectedUser()?.id;
};

axiosInstance.selectedKickId = () => {
  return axiosInstance.selectedUser()?.kickId;
};
