<template>
  <span class="font-weight-bold">{{ $t('upgrade-to-premium-by-pressing') }}</span>
  <kicklet-gradient-button
      class="ml-auto mr-auto mt-5 pa-8"
      :zoom-on-hover="true"
      text="Upgrade now!"
      color="yellow"
      color1="#ffd500"
      color2="#ffa200"
      @click="click"
  />
</template>

<script setup>
import KickletGradientButton from '@/components/custom/kicklet/KickletGradientButton.vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const emit = defineEmits(['close']);
function click () {
  router.push('/profile/premium');
  emit('close');
}
</script>

<style scoped>

</style>
