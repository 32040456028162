const OverlayOverview = () => import('@/views/widgets/Widgets');
const OverlayEdit = () => import('@/views/widgets/WidgetsEdit');

const routes = [
  {
    path: '',
    name: 'Widgets',
    redirect: to => ({ name: 'WidgetsSaved' }),
  },
  {
    path: 'saved',
    name: 'WidgetsSaved',
    meta: {
      breadcrumb: 'widget.my-widgets',
    },
    component: OverlayOverview,
  },
  {
    path: 'gallery',
    name: 'WidgetsGallery',
    meta: {
      breadcrumb: 'widget.gallery',
    },
    component: OverlayOverview,
  },
  {
    path: 'creator',
    name: 'WidgetsCreator',
    meta: {
      breadcrumb: 'Creator',
    },
    component: OverlayOverview,
  },
  {
    path: 'edit/:schemeId/:id?',
    name: 'WidgetsEdit',
    component: OverlayEdit,
  },
];

export default routes;
