import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from '@/store/auth';
import common from '@/store/common';
import alerts from '@/store/alerts';

export default new Vuex.Store({
  modules: {
    auth,
    common,
    alerts,
  },
  plugins: [createPersistedState({
    reducer: (state) => ({
      auth: {
        selectedCollaborator: state.auth.selectedCollaborator,
      },
      common: {
        locale: state.common.locale,
      },
    }),
  })],
  strict: true,
});
