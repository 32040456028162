import store from '@/store';

export class WS {
  static url = process.env.VUE_APP_API_GATEWAY_WS;
  static socket;
  static eventHandlers = {};
  static subscribed = [];
  static connectPromise;
  static auth;

  static async init (auth) {
    this.auth = auth;
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      if (this.connectPromise) {
        return await this.connectPromise;
      }

      return await this.connect();
    }
  }

  static async wait () {
    if (this.connectPromise) {
      return await this.connectPromise;
    }
  }

  static connect () {
    const socket = new WebSocket(this.url);
    this.socket = socket;
    this.setupMessageHandler();

    this.connectPromise = new Promise((resolve) => {
      socket.onopen = (event) => {
        if (this.auth || store.getters.accessToken) {
          let auth;
          if (this.auth) {
            auth = this.auth;
          } else {
            auth = {
              auth: store.getters.accessToken,
            };
          }

          this.send({
            action: 'auth',
            ...auth,
          });
        }

        this.connectPromise = undefined;
        resolve();

        setInterval(() => {
          this.ping();
        }, 10000);
      };

      socket.onclose = (event) => {
        this.disconnect();
        if (event.code !== 1000) {
          setTimeout(() => {
            this.connect();
          }, 3000);
        }
      };
    });

    return this.connectPromise;
  }

  static disconnect () {
    this.eventHandlers = {};
    this.subscribed = [];

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close(1000);
      this.socket = null;
    }
  }

  static setupMessageHandler () {
    this.socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);

        if (message.name) {
          const eventName = message.name;
          const messageData = message.message;

          if (this.eventHandlers[eventName]) {
            this.eventHandlers[eventName].forEach((handler) => {
              try {
                handler(messageData);
              } catch (e) {
                console.error(e);
              }
            });
          }
        }
      } catch (error) {
        console.log('Failed to parse WebSocket message:', event.data);
      }
    };
  }

  static subscribe (events) {
    if (typeof events === 'string') {
      events = [events];
    }

    this.send({
      action: 'subscribe',
      events,
    });

    this.subscribed.push(...events);
  }

  static subscribeUser (events) {
    if (typeof events === 'string') {
      events = [events];
    }

    events = events.map(event => `user/${this.selectedUserId()}/${event}`);
    this.subscribe(events);
  }

  static subscribePublicUser (userId, events) {
    if (typeof events === 'string') {
      events = [events];
    }

    events = events.map(event => `public/user/${userId}/${event}`);
    this.subscribe(events);
  }

  static unsubscribe (events) {
    if (typeof events === 'string') {
      events = [events];
    }

    this.send({
      action: 'unsubscribe',
      events,
    });

    this.subscribed = this.subscribed.filter((event) => !events.includes(event));

    if (this.subscribed.length === 0) {
      this.disconnect();
    }
  }

  static unsubscribeUser (events) {
    if (typeof events === 'string') {
      events = [events];
    }

    events = events.map(event => `user/${this.selectedUserId()}/${event}`);
    this.unsubscribe(events);
  }

  static unsubscribePublicUser (userId, events) {
    if (typeof events === 'string') {
      events = [events];
    }

    events = events.map(event => `public/user/${userId}/${event}`);
    this.unsubscribe(events);
  }

  static on (eventName, handler) {
    if (!this.eventHandlers[eventName]) {
      this.eventHandlers[eventName] = [];
    }

    this.eventHandlers[eventName].push(handler);
  }

  static onUser (eventName, handler) {
    eventName = `user/${this.selectedUserId()}/${eventName}`;
    this.on(eventName, handler);
  }

  static onPublicUser (userId, eventName, handler) {
    eventName = `public/user/${userId}/${eventName}`;
    this.on(eventName, handler);
  }

  static off (eventName, handler) {
    if (this.eventHandlers[eventName]) {
      const index = this.eventHandlers[eventName].indexOf(handler);
      if (index !== -1) {
        this.eventHandlers[eventName].splice(index, 1);
      }
    }

    const noEventHandlers = Object.keys(this.eventHandlers).every(
      (event) => this.eventHandlers[event].length === 0,
    );

    if (noEventHandlers) {
      this.disconnect();
    }
  }

  static offUser (eventName, handler) {
    eventName = `user/${this.selectedUserId()}/${eventName}`;
    this.off(eventName, handler);
  }

  static offPublicUser (userId, eventName, handler) {
    eventName = `public/user/${userId}/${eventName}`;
    this.off(eventName, handler);
  }

  static push (event, payload) {
    this.send({
      action: 'push',
      userId: this.selectedUserId().toString(),
      event,
      data: payload,
    });
  }

  static ping () {
    const pingMessage = {
      action: 'ping',
    };

    this.send(pingMessage);
  }

  static send (payload) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(payload));
    }
  }

  static selectedUser () {
    if (store.getters.selectedCollaborator) {
      return store.getters.selectedCollaborator.user;
    }

    return store.getters.user;
  }

  static selectedUserId () {
    return this.selectedUser()?.id;
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          ws: WS,
        };
      },
    });
  },
};
