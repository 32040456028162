import i18n from '@/i18n';

const state = {
  alerts: [],
};

const getters = {
  alerts: (state) => state.alerts,
};

const actions = {
  displayAlert (context, text) {
    const id = Date.now();
    const translatedText = i18n.global.t(text);
    context.commit('addAlert', {
      title: 'Kicklet',
      text: translatedText,
      id,
    });
    setTimeout(() => {
      context.commit('removeAlert', id);
    }, 3000);
  },
};

const mutations = {
  addAlert (state, alert) {
    state.alerts.push(alert);
  },
  removeAlert (state, id) {
    const index = state.alerts.findIndex(alert => alert.id === id);
    if (index !== -1) {
      state.alerts.splice(index, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
