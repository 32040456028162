import { axiosInstance } from '@/services/Service';

export class Stats {
  static http = axiosInstance;

  static selectedKickId () {
    return this.http.selectedKickId();
  }

  static getPointSettings () {
    return this.http.get(`/stats/${this.selectedKickId()}/settings/points`);
  }

  static setPointSettings (settings) {
    return this.http.put(`/stats/${this.selectedKickId()}/settings/points`, settings);
  }

  static getLivestreams (page = 1, pageSize = 10) {
    return this.http.get(`/stats/${this.selectedKickId()}/livestream`, {
      params: {
        page,
        pageSize,
      },
    });
  }

  static getLatestLivestream () {
    return this.http.get(`/stats/${this.selectedKickId()}/livestream`, {
      params: {
        page: 1,
        pageSize: 1,
      },
    })
      .then(resp => resp.data.livestreams[0]);
  }

  static getLivestreamById (id) {
    return this.http.get(`/stats/${this.selectedKickId()}/livestream/${id}`);
  }

  static getViewerRanking (page = 1, pageSize = 10, orderBy = 'watchtime', order = 'desc', search) {
    return this.http.get(`/stats/${this.selectedKickId()}/viewer/ranking`, {
      params: {
        page,
        pageSize,
        orderBy,
        order,
        search,
      },
    });
  }

  static getPublicViewerRanking (kickId, page = 1, pageSize = 10, orderBy = 'watchtime', order = 'desc', search) {
    return this.http.get(`/stats/public/${kickId}/viewer/ranking`, {
      params: {
        page,
        pageSize,
        orderBy,
        order,
        search,
      },
    });
  }

  static setViewerPoints (viewer, points) {
    return this.http.patch(`/stats/${this.selectedKickId()}/points/${viewer}/set/${points}`, points);
  }

  static editViewerStats (viewer, stats) {
    return this.http.patch(`/stats/${this.selectedKickId()}/viewer/${viewer}/stats`, stats);
  }

  static resetViewerStats (config) {
    return this.http.post(`/stats/${this.selectedKickId()}/viewer/reset`, config);
  }

  static getChartChatTotal (start, end, every = '1d') {
    return this.http.get(`/stats/${this.selectedKickId()}/series/chat/total`, {
      params: {
        start,
        end,
        every,
      },
    });
  }

  static getChartCommandsExecutions (start, end, every = '1d') {
    return this.http.get(`/stats/${this.selectedKickId()}/series/command/execution`, {
      params: {
        start,
        end,
        every,
      },
    });
  }

  static getPieChartCommandsExecutions (start, end) {
    return this.http.get(`/stats/${this.selectedKickId()}/pie/command/execution`, {
      params: {
        start,
        end,
      },
    });
  }

  static getEvents (page = 1, pageSize = 10, orderBy = 'createdAt', order = 'desc', search = '', filter = []) {
    return this.http.get(`/stats/${this.selectedKickId()}/events`, {
      params: {
        page,
        pageSize,
        orderBy,
        order,
        search,
        typeFilters: filter.join(','),
      },
    });
  }

  static getActiveSubscriptions (page = 1, pageSize = 10) {
    return this.http.get(`/stats/${this.selectedKickId()}/subscriptions/active`, {
      params: {
        page,
        pageSize,
      },
    });
  }

  static getPublicCurrencyName (kickId) {
    return this.http.get(`/stats/public/${kickId}/currency`);
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          stats: Stats,
        };
      },
    });
  },
};
