<template>
  <base-dialog
    v-model="dialog"
    class="dialog"
  >
    <template v-slot:title>{{ $t('dialog.logout.title') }}</template>
    <template v-slot:content>
      <p v-html="$t('dialog.logout.text')"></p>
    </template>
    <template v-slot:actions>
      <kicklet-button :text="$t('dialog.abort')" color="secondary" @click="abort"/>
      <kicklet-button :text="$t('dialog.logout.button')" color="error" @click="confirm"/>
    </template>
  </base-dialog>

</template>

<script setup>
import { computed } from 'vue';
import BaseDialog from '@/components/dialog/BaseDialog';
import KickletButton from '@/components/custom/kicklet/KickletButton.vue';

const emit = defineEmits(['update:modelValue', 'abort', 'confirm']);

const props = defineProps({
  titleItem: String,
  textItem: String,
  modelValue: Object,
});

const dialog = computed({
  get: () => props.modelValue.display,
  set: (val) => {
    emit('update:modelValue', {
      ...props.modelValue,
      error: null,
      display: val,
    });
  },
});

const abort = () => {
  dialog.value = false;
  emit('abort');
};

const confirm = () => {
  dialog.value = false;
  emit('confirm');
};
</script>

<style scoped>
.dialog {
  max-width: 500px;
}
</style>
