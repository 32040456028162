<template>
  <div class="alert-container">
    <transition-group name="alert" tag="div">
      <v-alert
          v-for="(alert) in store.getters.alerts"
          :key="alert.id"
          dense
          border="start"
          color="primary"
          dismissible
          class="ma-2 rounded-lg pa-2 pr-5 alert-item"
          variant="tonal"
          :title="alert.title"
          :text="alert.text"
          style="backdrop-filter: blur(20px); width: fit-content;"
      >
        <template v-slot:prepend>
          <img v-if="false" style="height: 25px; width: 25px;" :src="utils.getImgUrl('logo.png')" />
        </template>
        <template v-slot:title>
          <span class="font-weight-bold">{{alert.title}}</span>
        </template>
        <template v-slot:text>
          <span style="color: white;">{{alert.text}}</span>
        </template>
      </v-alert>
    </transition-group>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
const store = useStore();
</script>

<style scoped>
.alert-container {
  position: absolute;
  padding: 1rem;
  z-index: 99999999;
  pointer-events: none;
}

.alert-item {
  pointer-events: auto;
}

.alert-content {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: bold;
}

.alert-enter-active,
.alert-leave-active {
  transition: all 0.5s ease;
}

.alert-enter-from,
.alert-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
