import moment from 'moment/moment';
import i18n from '@/i18n';
import { useTheme } from 'vuetify';

export class Utils {
  static buttonVariant = 'elevated'; // default = elevated
  static buttonIconVariant = 'elevated'; // default = elevated
  static DISCORD_INVITE = 'https://discord.gg/Eb4VC5F5uU';
  static getImgUrl (img) {
    try {
      return require('../assets/img/' + img);
    } catch (e) {
      return undefined;
    }
  }

  static getVideoUrl (video) {
    try {
      return require('../assets/video/' + video);
    } catch (e) {
      return undefined;
    }
  }

  static getSvgUrl (svg) {
    try {
      return require('../assets/svg/' + svg + '.svg');
    } catch (e) {
      return undefined;
    }
  }

  static getBadgeUrl (svg) {
    try {
      return require('../assets/img/badges/' + svg + '.svg');
    } catch (e) {
      return undefined;
    }
  }

  static camelToKebabCase (str) {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  static formatDateTime (dateTime, format) {
    moment.locale(i18n.global.locale);
    return moment(dateTime).format(format);
  }

  static formatRelativeTime (dateTime) {
    moment.locale(i18n.global.locale);
    return moment(dateTime).fromNow();
  }

  static formatVideoTime (seconds) {
    const duration = moment.duration(seconds, 'seconds');
    return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
  }

  static getDocsUrl () {
    return 'https://kicklet.app/docs';
  }

  static overlayFonts () {
    return [
      'Anja Eliane',
      'BebasNeue',
      'Berlin Sans',
      'Bypass',
      'Cheesecake',
      'Malinda',
      'Minecrafter',
      'Nexa Heavy',
      'Poppins',
    ];
  }

  static calculatePercentage (votes, totalVotes) {
    return totalVotes > 0 ? Math.round((votes / totalVotes) * 100) : 0;
  }

  static calculateTimeRemaining (remaining, duration) {
    return Math.round((remaining / duration) * 100);
  }

  static round (num, decimalPlaces) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(num * multiplier) / multiplier;
  }

  static formatExecutionTime (nanos) {
    const milliseconds = nanos / 1e6;

    if (milliseconds < 1000) {
      if (milliseconds < 10) {
        return `${this.round(milliseconds, 2)}ms`;
      } else {
        return `${milliseconds.toFixed(0)}ms`;
      }
    } else {
      const seconds = milliseconds / 1000;
      return `${seconds.toFixed(3)}s`;
    }
  }

  static formatWatchTime (nanos) {
    moment.locale(i18n.global.locale);
    const duration = moment.duration(nanos / 1e6, 'ms');

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const formattedUnits = [];

    if (days > 0) {
      formattedUnits.push(`${moment.duration(days, 'days').humanize(false, { d: 1000 })}`);
    }

    if (hours > 0 && formattedUnits.length < 2) {
      formattedUnits.push(`${moment.duration(hours, 'hours').humanize(false, { h: 24 })}`);
    }

    if (minutes > 0 && formattedUnits.length < 2) {
      formattedUnits.push(`${moment.duration(minutes, 'minutes').humanize(false, { m: 60 })}`);
    }

    if (days === 0 && hours === 0 && minutes === 0) {
      formattedUnits.push(`${moment.duration(seconds, 'seconds').humanize()}`);
    }

    return formattedUnits.join(', ');
  }

  static formatFileSize (bytes) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const formattedSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

    return `${formattedSize} ${sizes[i]}`;
  }

  static formatPeriod (milliseconds) {
    const duration = moment.duration(milliseconds);
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let formattedDuration = '';
    if (days > 0) {
      if (days === 1) {
        formattedDuration += `${days} day `;
      } else {
        formattedDuration += `${days} days `;
      }
    }
    if (hours > 0) {
      if (hours === 1) {
        formattedDuration += `${hours} hour `;
      } else {
        formattedDuration += `${hours} hours `;
      }
    }
    if (minutes > 0) {
      if (minutes === 1) {
        formattedDuration += `${minutes} minute`;
      } else {
        formattedDuration += `${minutes} minutes`;
      }
    }

    return '⌛ ' + formattedDuration.trim();
  };

  static formatBytes (bytes) {
    if (bytes === 0) return '0 MB';
    const mb = bytes / (1000 * 1000);
    if (mb >= 1000) {
      const gb = mb / 1000;
      return `${gb.toFixed(1)} GB`;
    }
    return `${mb.toFixed(2)} MB`;
  }

  static matchWildcard (pattern, str) {
    const regex = new RegExp('^' + pattern.split('*').map(this.escapeRegex).join('.*') + '$');
    return regex.test(str);
  }

  static escapeRegex (str) {
    return str.replace(/[-/\\^$+?.()|[\]{}]/g, '\\$&');
  }

  static getVuetifyColor (colorName) {
    const theme = useTheme();
    const themeColors = theme.themes.value.default;
    return themeColors.colors[colorName] || '#ffffff';
  }

  static getColor (color) {
    if (!color) return '#ffffff';
    if (color.startsWith('#')) {
      return color;
    } else {
      return this.getVuetifyColor(color);
    }
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          utils: Utils,
        };
      },
    });
  },
};
