const Giveaway = () => import('@/views/giveaway/Giveaway');
const GiveawayPage = () => import('@/views/giveaway/GiveawayPage');

const routes = [
  {
    path: '',
    name: 'Giveaway',
    meta: {
      breadcrumb: false,
    },
    component: Giveaway,
  },
  {
    path: ':id',
    name: 'GiveawayPage',
    meta: {
      breadcrumbDynamic: true,
      breadcrumb: '{{giveaway.keyword}}',
    },
    component: GiveawayPage,
  },
];

export default routes;
