const Stats = () => import('@/views/stats/Stats');
const StatsViewers = () => import('@/views/stats/StatsViewers');
const StatsCharts = () => import('@/views/stats/StatsCharts');
const StatsSubscriber = () => import('@/views/stats/StatsSubscriber');
const StatsEvents = () => import('@/views/stats/StatsEvents');

const routes = [
  {
    path: '',
    name: 'Stats',
    meta: {
      breadcrumb: false,
    },
    component: Stats,
  },
  {
    path: 'viewer-ranking',
    name: 'StatsViewerRanking',
    meta: {
      breadcrumb: 'ViewerRanking',
    },
    component: StatsViewers,
  },
  {
    path: 'charts',
    name: 'StatsCharts',
    meta: {
      breadcrumb: 'Charts',
    },
    component: StatsCharts,
  },
  {
    path: 'subscriber',
    name: 'StatsSubscriber',
    meta: {
      breadcrumb: 'Subscriber',
    },
    component: StatsSubscriber,
  },
  {
    path: 'events',
    name: 'StatsEvents',
    meta: {
      breadcrumb: 'Events',
    },
    component: StatsEvents,
  },
];

export default routes;
