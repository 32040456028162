<template>
  <div ref="animRef" :style="{ visibility: isVisible ? 'visible' : 'hidden' }">
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import anime from 'animejs';

const props = defineProps({
  animationType: {
    type: String,
    default: 'fadeInUp',
  },
  easing: {
    type: String,
    default: 'easeOutElastic(1, .5)',
  },
  duration: {
    type: Number,
    default: 800,
  },
  delay: {
    type: Number,
    default: 0,
  },
  replay: {
    type: Boolean,
    default: false,
  },
  instant: {
    type: Boolean,
    default: false,
  },
});

const animRef = ref(null);
const isVisible = ref(false);
const activeAnimation = ref(null);
let observer = null;

onMounted(() => {
  if (props.instant) {
    isVisible.value = true;
    triggerAnimation(props.animationType);
  } else {
    observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (activeAnimation.value) {
          activeAnimation.value.pause();
        }
        if (entry.isIntersecting) {
          isVisible.value = true;
          triggerAnimation(props.animationType);
          if (!props.replay) {
            observer.unobserve(entry.target);
          }
        } else {
          activeAnimation.value = anime({
            targets: animRef.value,
            opacity: [1, 0],
            easing: 'linear',
            duration: 200,
            delay: 0,
            complete: () => {
              activeAnimation.value = null;
            },
          });
        }
      });
    }, {
      threshold: 0.5,
    });

    observer.observe(animRef.value);
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});

function triggerAnimation (animationType) {
  const animationPresets = {
    fadeInUp: () => {
      activeAnimation.value = anime({
        targets: animRef.value,
        translateY: [100, 0],
        opacity: [0, 1],
        easing: props.easing,
        duration: props.duration,
        delay: props.delay,
        complete: () => {
          activeAnimation.value = null;
        },
      });
    },
    fadeInRight: () => {
      activeAnimation.value = anime({
        targets: animRef.value,
        translateX: [-100, 0],
        opacity: [0, 1],
        easing: props.easing,
        duration: props.duration,
        delay: props.delay,
        complete: () => {
          activeAnimation.value = null;
        },
      });
    },
    fadeInLeft: () => {
      activeAnimation.value = anime({
        targets: animRef.value,
        translateX: [100, 0],
        opacity: [0, 1],
        easing: props.easing,
        duration: props.duration,
        delay: props.delay,
        complete: () => {
          activeAnimation.value = null;
        },
      });
    },
    zoomIn: () => {
      activeAnimation.value = anime({
        targets: animRef.value,
        scale: [0.6, 1],
        opacity: [0, 1],
        easing: props.easing,
        duration: props.duration,
        delay: props.delay,
        complete: () => {
          activeAnimation.value = null;
        },
      });
    },
  };

  const animate = animationPresets[animationType];
  if (animate) {
    animate();
  } else {
    console.error('Unsupported animation type.');
  }
}
</script>
