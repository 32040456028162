/* eslint-disable */
import * as monaco from 'monaco-editor';
import store from "@/store";
import i18n from "@/i18n";

export function init () {
  registerLanguage();
  registerTokenProvider();
  registerHoverProvider();
  registerCompletionItemProvider();
}

export function prepareBotVariableCompletions (variables) {
  return variables.map(variable => {
    let fullAccess = variable.name;
    if (variable.accessor) {
      if (variable.accessor.endsWith(':')) {
        fullAccess = `${variable.accessor}${fullAccess}`;
      } else {
        fullAccess = `${variable.accessor}.${fullAccess}`;
      }
    }

    let insertText;
    if (variable.params) {
      const paramStr = variable.params.map((param, i) => '${' + (i + 1) + ':' + param.name + '}').join(', ');
      insertText = `{${fullAccess}(${paramStr})}`;
    } else {
      insertText = `{${fullAccess}}`;
    }

    const completion = {
      label: fullAccess,
      kind: monaco.languages.CompletionItemKind.Variable,
      detail: variable.type,
      insertText,
    };

    if (variable.params) {
      completion.detail = '(' + variable.params.map(param => `${param.name}: ${param.type}`).join(', ') + ') => ' + variable.type;
      completion.kind = monaco.languages.CompletionItemKind.Function;
      completion.insertTextRules = monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet;
    }

    return completion;
  });
}

function registerLanguage () {
  monaco.languages.register({ id: 'quarkus-qute' });
  monaco.languages.setLanguageConfiguration('quarkus-qute', {
    brackets: [
      ['{', '}'],
      ['(', ')'],
      ['[', ']'],
    ],
    autoClosingPairs: [
      { open: '{', close: '}', notIn: ['string'] },
      { open: '[', close: ']', notIn: ['string'] },
      { open: '(', close: ')', notIn: ['string'] },
      { open: '"', close: '"' },
      { open: "'", close: "'" },
    ],
    comments: {
      blockComment: ['{!', '!}']
    },
  });
  monaco.editor.defineTheme('custom', {
    inherit: true,
    base: 'vs-dark',
    rules: [
      { token: 'keyword', foreground: '#cc7832' }
    ],
    colors: [],
  });
}

function registerCompletionItemProvider () {
  monaco.languages.registerCompletionItemProvider('quarkus-qute', {
    provideCompletionItems: function (model, position) {
      // Get the current word at the cursor position
      const wordInfo = model.getWordUntilPosition(position);
      const wordRange = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: wordInfo.startColumn,
        endColumn: wordInfo.endColumn,
      };

      const completions = [
        {
          label: 'test',
          kind: monaco.languages.CompletionItemKind.Field,
          insertText: 'test',
          range: wordRange,
        },
        {
          label: 'if',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: '{#if ${1:expression}}\n\t$0\n{/if}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: wordRange,
        },
        {
          label: 'if-else',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: '{#if ${1:condition}}\n\t$2\n{#else}\n\t$3\n{/if}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: wordRange,
        },
        {
          label: 'if-elseif',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: '{#if ${1:condition}}\n\t$2\n{#else if ${3:condition}}\n\t$4\n{#else}\n\t$5\n{/if}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: wordRange,
        },
        {
          label: 'let',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: '{#let ${1:name}=${2:value}}\n\t$3\n{/let}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: wordRange,
        },
        {
          label: 'switch',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: '{#switch ${1:value}}\n\t{#case ${2:case}}\n{/switch}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: wordRange,
        },
        {
          label: 'else',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: '{#else}',
          range: wordRange,
        },
        {
          label: 'for',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: '{#for ${1:item} in ${2:items}}\n\t{${3:item}}\n{/for}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          range: wordRange,
        },
      ];

      completions.push(...store.getters.monacoCompletions.map(c => ({ ...c, range: wordRange })));

      return {
        suggestions: completions,
      };
    },
  });
}

function registerTokenProvider () {
  monaco.languages.setMonarchTokensProvider('quarkus-qute', {
    keywords: [
      '#if', '/if',
      '#else', '/else',
      '#each', '/each',
      '#for', '/for',
      '#switch', '/switch',
      '#case', '/case',
      '#let', '/let',
      'in',
      'true',
      'false'
    ],

    tokenizer: {
      root: [
        [/(#|\/|[a-zA-Z_])[\w_]*/, {
          cases: {
            '@keywords': 'keyword',
            '@default': 'identifier',
          },
        }],

        [/[;,.]/, 'delimiter'],
        [/\{[A-Za-z\s.]*\}/, 'variable'],

        [/\{!(.*?)!}/, 'comment'],

        // whitespace
        { include: '@whitespace' },

        // numbers
        [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
        [/0[xX][0-9a-fA-F]+/, 'number.hex'],
        [/\d+/, 'number'],

        // strings
        [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-terminated string
        [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],
        [/'([^'\\]|\\.)*$/, 'string.invalid'], // non-terminated string
        [/'/, { token: 'string.quote', bracket: '@open', next: '@stringSingle' }],
      ],

      string: [
        [/[^\\"]+/, 'string'],
        [/\\./, 'string.escape.invalid'],
        [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }],
      ],

      stringSingle: [
        [/[^\\']+/, 'string'],
        [/\\./, 'string.escape'],
        [/'/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
      ],

      whitespace: [
        [/[ \t\r\n]+/, 'white'],
      ],

      variable: [
        [/}/, { token: 'delimiter.bracket', bracket: '@close', next: '@pop' }],
        [/[^}]+/, 'variable'], // Match variable name without curly braces
        [/./, '@rematch', '@pop'], // Anything else, pop the state
      ],
    },
  });
}

function provideHover(model, position) {
  // Get the text of the current line
  const lineContent = model.getLineContent(position.lineNumber);

  // Use a regular expression to find variables within curly braces, including whitespaces
  const regex = /{[^{}]*}/g;
  let match;
  while ((match = regex.exec(lineContent)) !== null) {
    const variableExpression = match[0];

    for(let i = 0; i < store.getters.commandVariables.length; i++) {
      const variable = store.getters.commandVariables[i];
      let qualifier = variable.name;
      if (variable.accessor) {
        if (variable.accessor.endsWith(':')) {
          qualifier = `${variable.accessor}${qualifier}`;
        } else {
          qualifier = `${variable.accessor}.${qualifier}`;
        }
      }

      let qualifierIndex;
      let startIndex = 0;
      do {
        qualifierIndex = variableExpression.indexOf(qualifier, startIndex);
        startIndex += qualifierIndex + 1;
        if (qualifierIndex !== -1) {
          if (qualifierIndex !== 0 && isLetter(variableExpression[qualifierIndex-1])) continue;
          const endIndex = qualifierIndex + qualifier.length - 1;
          if (endIndex < variableExpression.length && isLetter(variableExpression[endIndex+1])) continue;

          const startColumn = match.index + startIndex - 1;
          const endColumn = startColumn + qualifier.length;
          if (position.column >= startColumn && position.column <= endColumn) {
            const description = i18n.global.t(`variables.${qualifier}`);
            return {
              range: new monaco.Range(position.lineNumber, startColumn, position.lineNumber, endColumn),
              contents: [
                { value: '**' + qualifier + '**' },
                { value: description },
              ],
            };
          }
          break;
        }
      } while (qualifierIndex !== -1);
    }
  }

  return null;
}

function registerHoverProvider () {
  monaco.languages.registerHoverProvider('quarkus-qute', {
    provideHover: provideHover,
  });
}

function isLetter(char) {
  const letterRegex = /^[a-zA-Z]$/;
  return letterRegex.test(char);
}
