export class Analytics {
  static _id = 'G-22W70PRJME';

  static pageView (title, path) {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', this._id, {
        page_title: title,
        page_location: path,
      });
    }
  }

  static event (event, data) {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', event, data);
    }
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          gtag: Analytics,
        };
      },
    });
  },
};
