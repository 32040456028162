import { createApp } from 'vue';
import App from './App.vue';
import i18n from '@/i18n';
import router from '@/router';
import vuetify from '@/vuetify';
import utils from '@/modules/utils';
import imageGenerator from '@/services/ImageGenerator';
import kick from '@/services/Kick';
import analytics from '@/services/Analytics';
import backend, { Backend } from '@/services/Backend';
import stats from '@/services/Stats';
import overlay from '@/services/Overlay';
import event from '@/services/Event';
import ws from '@/services/WS';
import provider from '@/services/StoreProvider';
import discordAuth from '@/services/DiscordAuth';
import store from '@/store';
import * as monacoQute from '@/modules/monacoQute';
import * as monacoKicklet from '@/modules/monaco_lang_kicklet';
import '@/assets/main.scss';
import particles from '@tsparticles/vue3';
import { loadFull } from 'tsparticles';
import 'gridstack/dist/gridstack.min.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

monacoQute.init();
monacoKicklet.init();

Backend.me().catch(() => {});

window.addEventListener('storage', (event) => {
  if (event.key === 'me') {
    try {
      const me = JSON.parse(event.newValue);
      store.commit('setMe', me);
      if (!event.oldValue && event.newValue) {
        redirect();
      }
    } catch (e) {}
  }
});

function redirect () {
  const route = router.currentRoute.value.query.redirect;
  if (route) {
    router.push(decodeURIComponent(route));
  }
}

createApp(App)
  .use(i18n)
  .use(router)
  .use(vuetify)
  .use(utils)
  .use(imageGenerator)
  .use(kick)
  .use(analytics)
  .use(backend)
  .use(stats)
  .use(overlay)
  .use(event)
  .use(ws)
  .use(discordAuth)
  .use(store)
  .use(provider)
  .use(particles, {
    init: async engine => {
      await loadFull(engine);
    },
  })
  .component('VueDatePicker', VueDatePicker)
  .mount('#app');

window.addEventListener('storage', (event) => {
  if (event.key === 'accessToken') {
    store.commit('setAccessToken', event.newValue);
  } else if (event.key === 'refreshToken') {
    store.commit('setRefreshToken', event.newValue);
  }
});
