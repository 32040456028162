<template>
  <v-menu eager>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" aria-label="Locale Switcher" v-if="$i18n.availableLocales.length > 1">
        <img :src="utils.getImgUrl(`locales/${$i18n.locale}.png`)" class="locale-image" alt="locale switcher">
      </v-btn>
    </template>
    <v-list>
      <v-list-item
          class="pa-0"
          v-for="(locale, index) in $i18n.availableLocales"
          :key="index"
          :active="$i18n.locale === locale"
          @click="changeLocale(locale)"
      >
        <div class="d-flex justify-center align-center">
          <img :src="utils.getImgUrl(`locales/${locale}.png`)" class="locale-image" :alt="locale">
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>

import { useStore } from 'vuex';

const store = useStore();

const changeLocale = (locale) => {
  store.commit('setLocale', locale);
};
</script>

<style scoped>
.locale-image {
  width: 24px;
  height: 18px;
}
</style>
