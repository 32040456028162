import { axiosInstance } from '@/services/Service';
import store from '@/store';

export class Event {
  static http = axiosInstance;

  static userId () {
    return store.getters.userId;
  }

  static selectedUserId () {
    return this.http.selectedUserId();
  }

  static postTestEvent (event) {
    return this.http.post(`/event/${this.selectedUserId()}/test/${event}`);
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          event: Event,
        };
      },
    });
  },
};
