<template>
  <v-dialog
    v-model="dialog"
    class="dialog"
  >
    <v-card class="rounded-xl">
      <v-card-title>
        <v-icon icon="mdi-alert-circle" color="error"></v-icon>
        {{ $t(title) }}
      </v-card-title>
      <v-card-text class="color-secondary">
        <div v-html="$t(text, data)"/>
        <div v-if="text === 'backend.errors.limit exceeded' && !provider.isPremium" class="mt-2">
          <upgrade-premium @close="dialog = false" />
        </div>
        <div v-if="text === 'backend.errors.premium only' && !provider.isPremium" class="mt-2">
          <upgrade-premium @close="dialog = false" />
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <kicklet-button :text="$t('dialog.ok')" color="error" variant="elevated" @click="dialog = false"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed } from 'vue';
import KickletButton from '@/components/custom/kicklet/KickletButton.vue';
import UpgradePremium from '@/components/UpgradePremium.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  title: String,
  text: String,
  modelValue: Boolean,
  data: {
    type: Object,
    default: () => {},
  },
});

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});
</script>

<style scoped>
.dialog {
  max-width: 500px;
}
</style>
