<template>
  <span>{{ processedTitle }}</span>
</template>

<script setup>
import { computed } from 'vue';
import { Breadcrumb } from '@/modules/breadcrumb';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});

const processedTitle = computed(() => {
  return props.title.replace(/{{(.*?)}}/g, (match, p1) => {
    const key = p1.trim();
    const breadcrumb = Breadcrumb.get(key);
    return breadcrumb.value !== undefined ? breadcrumb.value : '';
  });
});
</script>

<style scoped>
</style>
