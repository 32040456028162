const ShopOverview = () => import('@/views/shop/ShopOverview');
const ShopItems = () => import('@/views/shop/ShopItems');
const ShopSales = () => import('@/views/shop/ShopSales');
const ShopItem = () => import('@/views/shop/ShopItemDetails');

const routes = [
  {
    path: '',
    name: 'ShopOverview',
    meta: {
      breadcrumb: false,
    },
    component: ShopOverview,
  },
  {
    path: 'items',
    meta: {
      breadcrumb: 'shop.items',
    },
    children: [
      {
        path: '',
        name: 'ShopItems',
        component: ShopItems,
        meta: {
          breadcrumb: false,
        },
      },
      {
        path: ':itemId',
        name: 'ShopItem',
        component: ShopItem,
        meta: {
          breadcrumbDynamic: true,
          breadcrumb: '{{shop.item}}',
        },
      },
    ],
  },
  {
    path: 'sales',
    name: 'ShopSales',
    meta: {
      breadcrumb: 'shop.sales',
    },
    component: ShopSales,
  },
];

export default routes;
