<template>
  <v-footer :app="true">
    <v-sheet class="w-100 footer-sheet">
      <v-divider :thickness="2" color="white" />
      <span class="footer-text sub-title-5">Copyright © {{new Date().getFullYear()}} by Kicklet. All rights reserved.</span>
      <div class="float-end">
        <v-row :no-gutters="true" style="min-width: 300px;">
          <v-col align-self="center" class="mr-5">
            <p style="width: 100px;" class="sub-title-5 clickable" @click="$router.push('/terms-of-service')">Terms of Service</p>
          </v-col>
          <v-col v-for="item in items" :key="item.tooltipText" align-self="center">
            <v-tooltip location="top" :text="item.translated ? $t(item.tooltipText) : item.tooltipText">
              <template v-slot:activator="{ props }">
                <a v-bind="props" class="link-without-decoration" :href="item.href" target="_blank">
                  <img v-if="!item.icon" class="icon" :src="item.imgSrc" :alt="item.imgAlt" :style="item.style || ''">
                  <v-icon v-else class="icon" :icon="item.icon" :alt="item.imgAlt" />
                </a>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-footer>
</template>

<script setup>
import { ref } from 'vue';
import { Utils } from '@/modules/utils';

const items = ref([
  {
    tooltipText: 'Discord',
    href: Utils.DISCORD_INVITE,
    imgSrc: Utils.getSvgUrl('discord'),
    imgAlt: 'Discord',
    icon: false,
  },
  {
    tooltipText: 'Kick',
    href: 'https://kick.com/kicklet',
    imgSrc: Utils.getSvgUrl('kick'),
    imgAlt: 'Kick',
    icon: false,
  },
  {
    tooltipText: 'X',
    href: 'https://twitter.com/kickletapp',
    imgSrc: Utils.getSvgUrl('x'),
    imgAlt: 'X',
    style: 'width: 20px;',
    icon: false,
  },
  {
    tooltipText: 'docs.title',
    translated: true,
    href: Utils.getDocsUrl(),
    imgSrc: '',
    imgAlt: 'Documentation',
    icon: 'mdi-file-document',
  },
]);
</script>

<style scoped>
.footer-sheet {
  height: 40px;
}

.icon {
  width: 30px;
  height: 30px;
  margin: 0 5px;
}

.footer-text {
  height: 40px;
  line-height: 40px;
}

.link-without-decoration {
  text-decoration: underline;
  text-decoration-color: inherit;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
</style>
