<template>
  <v-menu eager>
    <template v-slot:activator="{ props }">
      <v-list-item
        :slim="true"
        v-if="store.getters.isVerified"
        class="clickable rounded-lg"
        v-bind="props"
        :append-avatar="store.getters.selectedCollaborator?.user.profileImage ?? profileImageUrl"
        @click.once="loadManagedUsers"
      >
        <v-list-item-title>
          <span class="navigation-text" style="font-size: 14px;">{{ $t('editing-as-username') }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
                      <span class="navigation-text" style="font-size: 12px;">
                        {{ store.getters.selectedCollaborator?.user?.kickUsername || store.getters.kickUsername }}
                      </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-list
      class="justify-start align-start d-flex flex-column mr-3"
      style="gap: 4px;"
    >
      <v-list-item
        :slim="true"
        :prepend-avatar="profileImageUrl"
        @click="switchToMainUser"
        class="clickable w-100 rounded-lg"
        :active="store.getters.selectedCollaborator === null"
      >
        <v-list-item-title>
                      <span class="navigation-text" style="font-size: 12px;">
                        {{ store.getters.kickUsername }}
                      </span>
        </v-list-item-title>
        <template v-slot:append>
          <v-chip size="x-small" class="ml-3 font-weight-bold">{{ $t('you') }}</v-chip>
        </template>
      </v-list-item>
      <v-list-item
        :slim="true"
        v-for="(collab, index) in managedUsers"
        :key="index"
        :prepend-avatar="collab.user.profileImage"
        @click="switchManagedUser(collab)"
        class="clickable w-100 rounded-lg"
        :active="store.getters.selectedCollaborator?.user?.id === collab.user.id"
      >
        <v-list-item-title>
          <span class="navigation-text" style="font-size: 12px;">{{ collab.user.kickUsername ?? 'Unverified' }}</span>
        </v-list-item-title>
        <template v-slot:append>
          <v-tooltip :text="$t('delete')">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                size="small"
                variant="text"
                icon="mdi-delete"
                color="error"
                @click.stop="openDeleteManagedUsersDialog(collab)"
              ></v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
  <delete-dialog
    v-model="deleteManagedUsersDialog"
    :title-item="$t('collaborators.dialog.delete-managed-users.title')"
    :text-item="$t('collaborators.dialog.delete-managed-users.text', { name: deletionManagedUserText })"
    @confirm="deleteManagedUser"
  />
</template>

<script setup>
import { useStore } from 'vuex';
import DeleteDialog from '@/components/dialog/DeleteDialog';
import { computed, ref, watch } from 'vue';
import { Backend } from '@/services/Backend';
import { Kick } from '@/services/Kick';

const store = useStore();

const emit = defineEmits(['switched']);

const profileImageUrl = ref(null);
const managedUsers = ref(null);
const managedUsersDeletionItem = ref(null);
const deleteManagedUsersDialog = ref({
  display: false,
});

function switchManagedUser (collab) {
  store.commit('setSelectedCollaborator', collab);
  emit('switched', collab);
  window.location.reload();
  store.dispatch('displayAlert', 'user-switched');
}

function switchToMainUser () {
  store.commit('setSelectedCollaborator', null);
  emit('switched', null);
  window.location.reload();
  store.dispatch('displayAlert', 'user-switched');
}

function deleteManagedUser () {
  const managedUserId = managedUsersDeletionItem.value.user.id;
  Backend.deleteManagedUserCollaborator(managedUserId)
    .then(() => {
      loadManagedUsers();
    })
    .catch(error => {
      console.error('Error deleting managed user:', error);
    }).catch(err => Backend.handleError(err));
}

function openDeleteManagedUsersDialog (item) {
  managedUsersDeletionItem.value = item;
  deleteManagedUsersDialog.value.display = true;
}

const deletionManagedUserText = computed(() => {
  const item = managedUsersDeletionItem?.value;
  return item?.user.kickUsername;
});

const loadManagedUsers = () => {
  Backend.getManagedUsers()
    .then(response => {
      if (response.data !== managedUsers.value) {
        managedUsers.value = response.data.filter(collaborator => collaborator.user.kickId);
        managedUsers.value.forEach(collaboration => {
          Kick.getProfilePicUrl(collaboration.user.kickSlug)
            .then(resp => {
              collaboration.user.profileImage = resp;
            });
        });
      }
    }).catch(err => Backend.handleError(err));
};

Kick.getProfilePicUrl(store.getters.kickSlug)
  .then(url => {
    profileImageUrl.value = url;
  });

watch(() => store.getters.kickUsername, async (newKickUsername, oldKickUsername) => {
  if (newKickUsername !== oldKickUsername) {
    try {
      const url = await Kick.getProfilePicUrl(newKickUsername);
      profileImageUrl.value = url;
    } catch (error) {
      console.error('Error fetching profile picture URL:', error);
    }
  }
});
</script>

<style scoped>

</style>
