const Commands = () => import('@/views/commands/Commands');

const routes = [
  {
    path: 'default',
    name: 'DefaultCommands',
    meta: {
      breadcrumb: 'bot.default-commands.title',
    },
    component: Commands,
  },
  {
    path: 'custom',
    name: 'CustomCommands',
    meta: {
      breadcrumb: 'bot.commands.title',
    },
    component: Commands,
  },
  {
    path: 'gallery',
    name: 'CommandsGallery',
    meta: {
      breadcrumb: 'bot.gallery.title',
    },
    component: Commands,
  },
  {
    path: 'creator',
    name: 'CommandsCreator',
    meta: {
      breadcrumb: 'bot.creator.title',
    },
    component: Commands,
  },
];

export default routes;
